import React from 'react';
import { Link } from 'gatsby';
import { CalendarIcon } from '@heroicons/react/solid';
import Container from '../components/layout/default/container';
import { statePrettyName, formatDay, getMomentFromServerDate } from '../utils/helpers';
import SectionTitle from '../components/layout/admin/section-title';
import SEO from '../components/layout/seo';


function ProductsGridList({ products, state, league }) {
  const sortedProducts = [...products];
  sortedProducts.sort((a, b) => {
    const aCity = (a?.center?.city || '').toLowerCase();
    const bCity = (b?.center?.city || '').toLowerCase();
    // If within the same city, sort by start date
    if (aCity === bCity && a?.start_date && b?.start_date) {
      return getMomentFromServerDate(a.start_date).unix() - getMomentFromServerDate(b.start_date).unix();
    }
    if (aCity < bCity) {
      return -1;
    }
    if (aCity > bCity) {
      return 1;
    }
    return 0;
    // return b?.center?.city - a?.center?.city;
  });
  return (
    <div className="my-24">
      <SectionTitle title={`Upcoming leagues in ${statePrettyName(state)}`} />
      
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        {sortedProducts.map(({ id, center, name, start_date: startDate, start_time: startTime }) => (
          <div
            key={id}
            className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
          >
            <div className="flex-1 min-w-0">
              <Link to={`/${league.slug}/${center.slug}/${id}`} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-bold text-gray-900 truncate">{center.city}</p>
                <p className="text-sm mt-3 font-bold text-gray-900 truncate">{name}</p>
                <p className="text-sm flex items-center text-gray-700">
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                  {formatDay(startDate, 'MMMM Do YYYY')} at {startTime}
                </p>
                <p className="text-sm text-gray-600 truncate">{center.name}, {center.city}</p>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function League({ pageContext }) {
  const { league, productsByState } = pageContext;
  const states = Object.keys(productsByState);
  states.sort();

  const { name, short_description: shortDescription } = league;

  // console.log(league, productsByState, 'league and centers by state for that league...');

  return (
    <Container>
      <SEO title={league.name} />
      <div className="max-w-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Upcoming Leagues: {name}</h2>
        {!!shortDescription && (
          <p className="mt-4 text-base text-gray-500">{shortDescription}</p>
        )}
        {states.map((state) => <ProductsGridList key={state} state={state} league={league} products={productsByState[state]} />)}
      </div>
    </Container>
  );
}
